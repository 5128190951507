import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Modal, Button, Header } from "..";
import { POST } from "../../Shared/Axios";
import {  API_SWIP_URLS } from "../../Shared/Constant";

function Subscribe() {
  const [modal, setModal] = useState(false);
  const [loading,setLoading]=useState(false)
  const navigate = useNavigate();
  const getMsisdn=window.localStorage.getItem("msisdn")
  const OnHandleUnsubscribe = () => {
    setLoading(true)
    const data = { operatorId: "mtn_congo", userId: getMsisdn };
    POST(API_SWIP_URLS.unsubscribe, data)
      .then((res) => {
        console.log(res);
        setModal(true);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  return (
    <div className="main">
      {loading?<Loader />:""}
      <Header />
      
      { modal ? (
        <Modal text={"Votre demande de désabonnement a abouti."} type="modal" setModal={setModal} />
      ) : (
        ""
      )}
      <section class="card_container">
      <div className="card">

        <p className="font-medium text-center text-2xl">
          { "En vous désabonnant vous perdrez tous vos points cumulés. Veuillez cliquer sur Se désabonner pour confirmer. "}
        </p>
       
          <Button
            name="Se désabonner"
            className="bg-sky-800 rounded-full text-white px-4 py-2 my-3 text-lg font-medium"
            handleClick={OnHandleUnsubscribe}
          />
           <div className="text-blue-600 " onClick={() => navigate("/")}>
          {"Accueil"}
        </div>
      </div>
      </section>
    </div>
  );
}

export default Subscribe;
