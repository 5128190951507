import React from "react";
import Header from "../Header";

const HowToPlay = () => {
  return (
    <div className="main">
      <Header />
      <section className="info-container">
        <div className="card_menu pl-3">
          <div class="card-body">
            <h1 className={`text-center text-xl font-bold my-2 `}>
              { "MTN Gains"}
            </h1>
            <div>
              <h6 className={`font-bold flex text-lg mb-2`}>
                { "COMMENT JOUER:"}
              </h6>
              <ul className={`text-gray-500 pl-4 list-decimal list-outside`}>
                <li>
                  { "L'utilisateur doit s'abonner au service."}
                </li>
                <li>
                  {"L'utilisateur recevra le SMS après un abonnement réussi, où le lien sera là pour jouer au service."}
                </li>
                <li>
                  { "Pour répondre, balayez simplement vers la droite ou la gauche de l'écran"}
                </li>
                <li>
                  { "L'tilisateur obtiendra 10 points pour chaque réponse correcte"}
                </li>
                <li>
                  { "L'utilisateur a besoin des données mobiles MTN pour jouer au jeu et bénéficier du service"}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default HowToPlay;
