import React, { useEffect, useMemo, useRef, useState } from "react";
import { POST } from "../Shared/Axios";
import { API_SWIP_URLS } from "../Shared/Constant";
import "./swip4win.scss";
import { useNavigate } from "react-router-dom";
import { Header, Modal, Loader } from "../Components";
import CardTemp from "./Card";
import { CorrectIcon, WrongIcon, BtnBg } from "../Utilies/ExportImages";
import { SendGuiDataEvents } from "../commonScript";

export const Swipe4win = () => {
  const [questions, setQuestions] = useState("");
  const [startData, setStartData] = useState("");
  const [count, setCount] = useState(10);
  const [quizEnd, setQuizEnd] = useState("");
  const [loading, setLoading] = useState(false);
  const [buypopup, setBuypopup] = useState(false);
  const [endQuestion, setEndQuestion] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [loadingTime, setLoadingTime] = useState(true);
  const [ansApiCount,setAnsApiCount]=useState(0)

  useEffect(() => setCurrentIndex(questions.length - 1), [questions]);
  const navigate = useNavigate();
  const getMsisdn = window.localStorage.getItem("msisdn");
  useEffect(() => {
    if (questions && currentIndex < 0 && questions.length===ansApiCount) {
      setLoading(true);
      const endData = {
        userId: getMsisdn,
        sessionId: startData.sessionId,
        operatorId: "mtn_congo",
      };
      POST(API_SWIP_URLS.End, endData)
        .then((res) => {
          setLoading(false);
          setCount(0);
          setEndQuestion(true);
          setQuizEnd(res);
          let guiEvents={};
    guiEvents['page']="result";
    guiEvents["event"] = "quiz_completed";
    SendGuiDataEvents(guiEvents);

        })
        .catch((err) => console.log(err));
    }
  }, [currentIndex,ansApiCount]);

  useEffect(() => {
    setLoading(true);
    const data = {
      operatorId: "mtn_congo",
      questions: true,
      userId: getMsisdn,
    };
    POST(API_SWIP_URLS.Questions, data)
      .then((res) => {
        setLoading(false);
        console.log(res);
        setQuestions(res.questions);
        if(res.status=="SUCCESS"){
          const numofQuestions= res.questions.length
        console.log(numofQuestions,"number")
        let guiEvents={};
        guiEvents['numofQuestions']=numofQuestions; 
    guiEvents['page']="question";
    guiEvents["event"] = "total_questions";
    SendGuiDataEvents(guiEvents);
         }
  
        if (
          res.status == "FAILURE" &&
          res.reason !== "" &&
          res.questions.length == 0
        ) {
          navigate("/home");
        }
        if (!res.questions.length && res.packs.length) {
          navigate("/buy", { state: { questions: res } });
        }
        if (res?.packs && res?.packs[0]?.packType == "SUBSCRIPTION" && res?.status.toUpperCase() === "FAILURE") {
          const heUrl = localStorage.getItem("heUrl");
          window.location.replace(heUrl);
        }
        if (!res.questions.length && !res.packs.length) {
          navigate("/home");
        }
      })
      .catch((err) => console.log(err));
    POST(API_SWIP_URLS.Start, data)
      .then((res) => setStartData(res))
      .catch((err) => console.log(err));
    if (currentIndex == -1) {
      if (questions.packs.length) {
        setBuypopup(true);
      } else {
        navigate("/info");
      }
    }
  }, []);

  useEffect(() => {
    if (count > 0) {
      var interval = setInterval(() => {
        setCount((count) => count - 1);
      }, 1000);
    }
    if (currentIndex !== -1 && count == 0) {
      swipe("up");
    }
    return () => clearInterval(interval);
  }, [count]);

  function reset() {
    setCount(10);
  }

  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(questions.length)
        .fill(0)
        .map((i) => React.createRef()),
    [questions]
  );
  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };
  // const swiped = (direction, index) => {
  //   updateCurrentIndex(index - 1);
  //   console.log(direction, "dir");
  //   if (direction == "left") {
  //     const data = {
  //       userId: getMsisdn,
  //       sessionId: startData.sessionId,
  //       operatorId: "mtn_congo",
  //       useragent:
  //         "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:106.0) Gecko/20100101 Firefox/106.0",
  //       answers: [
  //         {
  //           answer: false,
  //           questionId: questions[currentIndex]?.questionId,
  //           timeout: 1,
  //         },
  //       ],
  //     };
  //     POST(API_SWIP_URLS.Answer, data)
  //       .then((res) => {
  //         console.log(res, "left");
  //       })
  //       .catch((err) => err);
  //   } else if (direction == "right") {
  //     const data = {
  //       userId: getMsisdn,
  //       sessionId: startData.sessionId,
  //       operatorId: "mtn_congo",
  //       useragent:
  //         "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:106.0) Gecko/20100101 Firefox/106.0",
  //       answers: [
  //         {
  //           answer: true,
  //           questionId: questions[currentIndex]?.questionId,
  //           timeout: 0,
  //         },
  //       ],
  //     };
  //     POST(API_SWIP_URLS.Answer, data)
  //       .then((res) => {
  //         console.log(res, "right");
  //       })
  //       .catch((err) => err);
  //   } else if (direction == "up") {
  //     const data = {
  //       userId: getMsisdn,
  //       sessionId: startData.sessionId,
  //       operatorId: "mtn_congo",
  //       useragent:
  //         "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:106.0) Gecko/20100101 Firefox/106.0",
  //       answers: [
  //         {
  //           answer: false,
  //           questionId: questions[currentIndex]?.questionId,
  //           timeout: 1,
  //         },
  //       ],
  //     };
  //     POST(API_SWIP_URLS.Answer, data)
  //       .then((res) => {
  //         console.log(res, "up");
  //       })
  //       .catch((err) => err);
  //   }
  // };

  const swipe = (dir) => {
    if (currentIndex < questions.length) {
      childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
    // swiped(dir, currentIndex);
    if (dir) {
      console.log("inside dir swipe", dir);
      reset();
    }
  };
  const btnStyle = {
    backgroundImage: `url(${BtnBg})`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    width: "fit-content",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    zIndex: 2,
  };

  const handleImageLoad = () => {
   
    setLoadingTime(false);
  };


  return (
    <div className="main overflow-hidden" >
      {loading ? <Loader /> : ""}
      <Header />
      <div className="flex flex-col p-2 overflow-hidden space-x-1">
        <div className="relative text-center sm:-mt-1 font-bold text-white">
          <h1 className="badge sm:p-1 p-2 w-auto sm:rounded-2xl rounded-3xl bg-gray-900 bg-opacity-20 ">
            {"Glissez la carte à droite et à gauche"}
          </h1>
        </div>
        <div className="swipe -mt-9 sm:-mt-4 ">
          {questions.length && Object.keys(startData).length ? (
            <CardTemp
              questions={questions}
              startData={startData}
              childRefs={childRefs}
              setCurrentIndex={setCurrentIndex}
              setCount={setCount}
              setAnsApiCount={setAnsApiCount}
            />
          ) : null}
        </div>
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2  flex flex-col justify-center items-center ">
          <div className="badge rounded-3xl w-fit p-2 sm:p-1 mb-2 sm:-mb-1 text-white font-bold bg-gray-800 bg-opacity-20">
            {count > 9 ? `00:${count}s` : `00:0${count}s`}
          </div>
          <div className="m-auto btns-bg sm:-mb-3" style={btnStyle}>
            <div className="flex flex-row mt-1 m-auto ">
              <img
                src={WrongIcon}
                onClick={() => swipe("left")}
                alt=""
                width="80"
                className="cursor-pointer"
              />
              <img
                src={CorrectIcon}
                className="ml-1 cursor-pointer"
                onClick={() => swipe("right")}
                alt=""
                width="80"
              />
            </div>
          </div>
        </div>
        {endQuestion ? (
          <Modal
            type="result"
            quizEnd={quizEnd}
            setCount={setCount}
            setQuestions={setQuestions}
            questions={questions}
            currentIndex={currentIndex}
            handleImageLoad={handleImageLoad}

          />
        ) : (
          ""
        )}
        {buypopup && <Modal type="buypopup" />}
        <div className="swipe-cards-background sm:-mt-2"></div>
      </div>
    </div>
  );
};
