import React from "react";
import { Header } from "..";

const Faq = () => {
  return (
    <div className="main">
      <Header />
      <section className=" info-container">
        <div class="card_menu">
          <div className="card-body">
            <h1 className={`text-center font-bold text-xl `}>
              { "Questions fréquemment posées"}
            </h1>
            <div className="p-4 list-data">
              <h6 className={`font-semibold `}>
                {"Comment je m'abonne ?"}
              </h6>
              <p className={`mb-0  `}>
                {"Vous pouvez vous abonner au service 'MTN Gains' en :"}
              </p>
              <ul className={`text-gray-500 list-outside list-disc pl-3  `}>
                <li className=" ">
                  {" L'utilisateur peut s'abonner au service via WEB en cliquant sur ce lien "}
                
                {/* <br /> */}
                <a
                  className="text-[#007bff] text-base font-normal"
                  href="https://mtngains.com"
                >
                  https://mtngains.com
                </a>
                </li>
              </ul>

              <h6 className={`font-semibold `}>
                { "Quelle est la durée de la prestation ?"}
              </h6>
              <p className="mb-4">
                { "Le service a un pack quotidien."}
              </p>

              <h6 className={`font-semibold`}>
                {" Si je gagne, comment serai-je informé ? "}
              </h6>
              <p className={`mb-4 `}>
                {"Le gagnant sera annoncé par SMS."}
              </p>
              <h6 className={`font-semibold`}>
                { " Combien de fois puis-je gagner chaque prix ? "}
              </h6>
              <p className={`mb-4`}>
                { " Chaque utilisateur est éligible pour gagner les prix un nombre illimité de fois. "}
              </p>

              <h6 className={`font-semibold `}>
                { " Quelles sont les règles du jeu MTN Gains ? "}
              </h6>
              <ul className="list-disc list-outside text-gray-500 pl-3">
                <li>
                  { "Chaque question est composée d'une déclaration et d'une photo"}
                </li>
                <li>
                  {" "}
                  { " L'utilisateur doit glisser vers la droite pour Correct ou vers la gauche pour Mauvais. "}
                </li>
                <li className="mb-4">
                  {" "}
                  { "Chaque bonne question vous rapporte 10 points"}
                </li>
              </ul>

              <h6 className={`font-semibold`}>
                {"Comment puis-je me désinscrire ?"}
              </h6>
              <p className="mb-4">
                { " Pour annuler votre abonnement 'MTN Gains', cliquez sur le bouton Se désabonner dans le menu des paramètres. "}
              </p>

              <h6 className={`font-bold `}>
                { " Je ne suis pas abonné à MTN ; puis-je jouer au jeu ? "}
              </h6>
              <p className="mb-4">
                { "Non, seuls les abonnés MTN peuvent bénéficier de ce service."}
              </p>

              <h6 className={`font-bold`}>
                { "Mes crédits MTN Gains expirent-ils ?"}
              </h6>
              <p>
                {" Les crédits MTN Gains expirent à la fin de la période de validité s'ils ne sont pas consommés jusque-là. "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Faq;
