import React, { useState } from "react";
import { SwipeLogo, Menu } from "../Utilies/ExportImages";
import { useNavigate } from "react-router-dom";
import SideBar from "./SideBar/Sidebar";
import "./header.scss";

function Header() {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);

  const onHandleNav = () => {
    setShowNav(!showNav);
   // setTimerStop(!timerStop);
   
  };

  return (
    <>
      <div className="header">
        <div className="header_icon_Swip">
          <span className="logo_main">
            <img
              src={SwipeLogo}
              alt="SwipenWin"
              width="140"
              onClick={() => navigate("/home")}
            />
          </span>
        </div>
        <div className="header_icon">
          <span>
            <img src={Menu} alt="Menu" width="36" onClick={()=>onHandleNav()} />
          </span>
        </div>
      </div>
      <div
        className={`navbar ${
          showNav
            ? "showNav bg-[#4a4d4e]"
            : "hideNav"
        }`}
      >
        {" "}
        <p
          className="absolute pl-5 pr-5 pb-3 pt-3 font-bold mt-2 ml-2 bg-[#64a4d1] rounded-full text-white cursor-pointer"
          onClick={onHandleNav}
        >
          X
        </p>
        <SideBar />
      </div>
    </>
  );
}

export default Header;
