import React, { useState, useEffect } from "react";
import "./leaderBoarder.scss";
import { avtar, BannerImg } from "../../Utilies/ExportImages";
import { Header, Loader } from "..";
import { API_SWIP_URLS } from "../../Shared/Constant";

import { POST } from "../../Shared/Axios";
const LeaderBoard = () => {
  const [leaderBoardData, setLeaderBoardData] = useState("");
  const [showWeeklydata, setShowWeeklydata] = useState(false);
  const [showMonthlydata, setShowMonthlydata] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userScore, setUserScore] = useState([]);

  useEffect(() => {
    LeaderApi();
  }, []);

  const getMsisdn = window.localStorage.getItem("msisdn");
  const LeaderApi = () => {
    setIsLoading(true);
    const data = { operatorId: "mtn_congo", userId: getMsisdn };
    POST(API_SWIP_URLS.LeaderBoard, data)
      .then((res) => {
        setLeaderBoardData(res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="main" style={{ overflowY: "scroll" }}>
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="">
            <div className="-ml-4 -mr-4">
              <img src={BannerImg} width="500px" alt="mtn_banner" lazy="true" />{" "}
            </div>
          </div>
          {showWeeklydata || showMonthlydata ? (
            <div>
              {userScore && (
                <div className={`flex mt-8 mb-2  bg-[#90a6b5] rounded-lg `}>
                  <div
                    className={`w-1/7 bg-[#623494] font-bold rounded-l-lg p-4 text-white text-center justify-center `}
                  >
                    {leaderBoardData?.yourscore?.rank <= 9
                      ? `0${leaderBoardData?.yourscore?.rank}`
                      : leaderBoardData?.yourscore?.rank}
                  </div>
                  <div className="w-1/7 mt-auto mb-auto ml-2 text-white">
                    <img
                      src={avtar}
                      alt="avtar"
                      width="36"
                      className="rounded-full"
                    />
                  </div>
                  <div className={`flex flex-col text-left pr-2  pl-4`}>
                    {leaderBoardData?.yourscore?.username}
                    <div className={`w-full `}>
                      {`Vos Points:${leaderBoardData?.yourscore?.score}`}
                    </div>
                  </div>
                </div>
              )}
              <div>
                {leaderBoardData
                  ? leaderBoardData.leaderboard
                      .filter((item) => item.score > 0)
                      .map((item, i) => {
                        return (
                          <div
                            className="flex  bg-[#9979BB] rounded-lg mb-2"
                            key={i}
                          >
                            <div
                              className={`w-1/5 bg-[#623494] font-bold rounded-l-lg p-4  text-white text-center justify-center 
                          `}
                            >
                              {item?.rank <= 9 ? `0${item.rank}` : item.rank}
                            </div>
                            <div className="w-1/6 mt-auto mb-auto ml-2 text-white">
                              <img
                                src={avtar}
                                alt="avtar"
                                width=""
                                className="rounded-full"
                              />
                            </div>
                            <div className={`flex flex-col text-left pr-2`}>
                              {item?.username}
                              <div className={`w-full `}>
                                {`Vos Points:${item?.score}`}
                              </div>
                            </div>
                          </div>
                        );
                      })
                  : ""}
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </>
      )}
    </div>
  );
};
export default LeaderBoard;
