import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LikeIcon, RightIcon } from "../Utilies/ExportImages";
import { API_SWIP_URLS } from "../Shared/Constant";
import { Loader, Button, DecodeBase64 } from ".";
import { POST } from "../Shared/Axios";

export const Modal = ({
  text,
  type,
  title,
  subTitle,
  sub,
  congrats,
  setCount,
  setQuestions,
  congres_button,
  quizEnd,
  setModal,
}) => {
  const navigate = useNavigate();
  const [buyMore, setBuyMore] = useState(false);
  const [results, setResult] = useState(true);
  const [congsData, setCongsData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [counter, setCounter] = useState(false);
  const [progress, setProgress] = useState(0);
  const [pending, setPending] = useState(false);
  const [modalCount, setModalCount] = useState(0);

  const [question, setQuestion] = useState({});
  const getMsisdn = window.localStorage.getItem("msisdn");

  const onHandlePlayCongress = () => {
    navigate("/swipe-cards");
  };
  useEffect(() => {
    if (type == "buypopup") {
      setBuyMore(true);
    }
  }, []);

  useEffect(() => {
    console.log(counter, "counter");
    if (counter) {
      console.log("count", modalCount);
      const interval = setInterval(() => {
        if (type == "timer" || counter) {
          if (progress === 90) {
            console.log("question api hit");
            const data = {
              operatorId: "mtn_congo",
              questions: true,
              userId: getMsisdn,
            };

            POST(API_SWIP_URLS.Questions, data)
              .then((res) => {
                setQuestion(res);
                setProgress(0);

                if (res.status == "FAILURE" && modalCount !== 1) {
                  setPending(true);
                  setBuyMore(false);
                  setCounter(false);
                } else if (res.status == "FAILURE" && modalCount === 1) {
                  setPending(false);
                  setBuyMore(false);
                  setCounter(true);
                  setModalCount(modalCount + 1);
                } else if (res.questions && res.questions.length > 0) {
                  console.log("api changes", res.questions.length);
                  setCounter(false);
                  setPending(false);
                  setBuyMore(true);
                } else if (res.status == 500) {
                  console.log("api failed");
                  navigate("/");
                } else {
                  navigate("/");
                }
              })
              .catch((err) => console.log(err));
          }
        }
        setProgress((progress) => progress + 10);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [progress, counter]);

  const Parentdiv = {
    height: "20px",
    width: "80%",
    backgroundColor: "whitesmoke",
    borderRadius: 40,
    boxShadow: "0px 1px 6px 2px #918787",
  };

  const Childdiv = {
    height: "100%",
    maxWidth: "100%",
    width: `${progress}%`,
    background: "#17a2b8",
    // background:"-webkit-linear-gradient(white, #38495a)",
    borderRadius: 40,
    textAlign: "right",
  };

  const onHandlePlay = () => {
    window.location.reload();
    setCount(10);
    setBuyMore(false);
    setIsLoading(true);
    const data = {
      operatorId: "mtn_congo",
      questions: true,
      userId: getMsisdn,
    };
    POST(API_SWIP_URLS.Questions, data)
      .then((res) => {
        setQuestions(res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (quizEnd?.status == "FAILURE" && !quizEnd.packs.length) {
      navigate("/info");
    } else if (quizEnd?.status == "FAILURE" && quizEnd.packs.length) {
      navigate("/buy", { state: { questions: quizEnd } });
    }
  }, [quizEnd]);

  const onHandleClick = () => {
    setBuyMore(false);
    setResult(false);
    setIsLoading(true);
    const data = {
      operatorId: "mtn_congo",
      packId: "dailytopup",
      userId: getMsisdn,
    };
    POST(API_SWIP_URLS.Buy, data)
      .then((res) => {
        setCongsData(res.info);
        setIsLoading(false);
        console.log("buy api hit");
        setCounter(true);
        setModalCount(modalCount + 1);
      })
      .catch((err) => console.log(err));
  };
  const handleQuestion = () => {
    setIsLoading(true);
    console.log(question, "question");
    if (question.questions.length > 0) {
      console.log("inside questionss");
      navigate("/swipe-cards");
    } else if (
      question?.packs &&
      question?.packs[0]?.packType == "SUBSCRIPTION"
    ) {
      setIsLoading(false);
      const heUrl = localStorage.getItem("heUrl");
      window.location.replace(heUrl);
    } else {
      console.log("buy pageeee>>>>>>>>");
      navigate("/buy", { state: { questions: question } });
    }
  };
  console.log(quizEnd,"quiz end");
  const closeModal = ()=>{
    setModal(false)
  }
  return (
    <div className="main">
      {isLoading ? <Loader /> : ""}
      {type == "modal" && (
        <div className=" Modal flex flex-col justify-center items-center">
          <div className="modal_fade  bg-white rounded-md m-4 p-4 max-w-[360px] flex flex-col justify-center items-center">
            <div className=" w-full font-extrabold cursor-pointer text-right pr-2 pb-2" onClick={closeModal}>X</div>
            <p className="text-xl text-center mt-2">{text}</p>
            <br></br>
            <div className="">
              <Button
                name=" d'accord "
                className="bg-sky-800 py-3 px-28 my-3 rounded-full text-white text-xl font-semibold m-auto"
                handleClick={() => navigate("/")}
              />
            </div>
          </div>
        </div>
      )}
      {type == "congress" && (
        <div className="Modal flex flex-col justify-center items-center ">
          <div className="modal_fade  bg-white rounded-xl m-4 p-4 max-w-[480px] flex flex-col justify-center items-center">
            <img src={RightIcon} alt="right-icon" width="80" />
            <h2
              className={`text-3xl text-[#28a745] text-center font-semibold mt-2 `}
            >
              {title ? DecodeBase64(title) : ""}
            </h2>
            <p className={`mt-2 text-lg text-center font-medium `}>
              {subTitle ? DecodeBase64(subTitle) : ""}
            </p>
            <div className={`text-center mt-2 `}>
              {sub ? DecodeBase64(sub) : ""}
            </div>
            {congrats.reason !== "" && congrats.info !== {} && (
              <>
                {console.log("inside buy api failed", congrats)}
                <p className="mb-3 text-xl text-center">
                  Nous n'avons pas pu traiter votre demande. Veuillez réessayer
                  après un certain temps.
                </p>
              </>
            )}
            <div className="flex  ">
              <Button
                name={congres_button ? DecodeBase64(congres_button) : "Ok"}
                handleClick={onHandlePlayCongress}
                className={`w-full py-2 px-32 text-lg bg-sky-800 m-auto text-white hover:bg-gray-600 font-bold rounded-3xl mt-4 
                `}
              />
            </div>
          </div>
        </div>
      )}
      {type === "result" && !buyMore && results && (
        <div className="Modal flex z-20  flex-col justify-center items-center  ">
          <div className="modal_fade max-w-[480px]  bg-white rounded-xl p-4 m-4 flex flex-col justify-center items-center">
            <img src={LikeIcon} alt="right-icon" width="80" />
            <h2
              className={`text-2xl text-center font-bold my-2 text-4xl text-red-800 `}
            >
              
              {quizEnd ? DecodeBase64(quizEnd?.greet?.title) : ""}
            </h2>
            <p className={`mt-2 text-center `}>
              {quizEnd ? DecodeBase64(quizEnd?.greet?.subtitle) : ""}
            </p>
            <div className="border-4 rounded-full my-3  border-red-800 h-16 w-16 flex flex-col justify-center items-center">
              <div
                className={`text-center p-4 font-bold text-4xl text-red-800 `}
              >
                {quizEnd ? quizEnd?.correctAnswers : ""}
              </div>
            </div>
            <div className={`text-center font-bold `}>
              {quizEnd ? DecodeBase64(quizEnd?.greet?.correctText) : ""}
            </div>
            <div className={`text-center mt-2 `}>
              {quizEnd ? DecodeBase64(quizEnd?.greet?.text) : ""}
            </div>
            <div className={`text-center mt-2 `}>
              {quizEnd ? DecodeBase64(quizEnd?.greet?.next) : ""}
            </div>
            <hr className="w-full text-gray-500 my-4" />
            {quizEnd.packs.length ? (
              <div className="w-full h-full p-1 bg-yellow-100 rounded-xl border-l-8 border-sky-800 m-2">
                <div className={`p-2 text-center`}>
                  {quizEnd?.packs && DecodeBase64(quizEnd?.packs[0]?.packName)}
                </div>
                <div className={`p-2 text-center`}>
                  {quizEnd?.packs && DecodeBase64(quizEnd?.packs[0]?.description)}
                </div>
                <div className="flex justify-center">
                  <Button
                    name={
                      quizEnd?.packs &&
                      DecodeBase64(quizEnd?.packs[0]?.buttonText)
                    }
                    className={`button-theme bg-sky-800 hover:bg-sky-600  pr-4 pl-4 pt-2 pb-2 my-2 font-bold  text-white rounded-3xl mb-2 
                    `}
                    handleClick={onHandleClick}
                  />
                </div>
              </div>
            ) : (
              <div
                onClick={onHandlePlay}
                className={`button-theme hover:bg-sky-600  bg-sky-800 pr-8 pl-8 pt-2 pb-2 font-bold  text-white rounded-3xl my-2 
               `}
              >
                {quizEnd && DecodeBase64(quizEnd?.greet?.nextButton)}
              </div>
            )}
            {quizEnd?.packs.length ? (
              <Button
                name={quizEnd?.packs && DecodeBase64(quizEnd?.packs[0]?.notNow)}
                className={` pr-4 pl-4 pt-2 pb-2 my-2 font-bold  text-blue-400  mb-2 
                    `}
                handleClick={onHandlePlay}
              />
            ) : null}
          </div>
        </div>
      )}
      {buyMore && (
        <div className="Modal  animate-fade flex z-30 flex-col justify-center items-center ">
          <div className="modal_fade bg-white max-w-[480px] rounded-xl p-4 m-4 flex flex-col justify-center items-center">
            <img src={LikeIcon} alt="right-icon" width="80" />
            <h2
              className={`text-2xl text-center font-bold mt-2 text-2xl text-green-600`}
            >
              {"Toutes nos félicitations!"}
            </h2>
            <p className={`mt-2 text-center font-semibold `}>
              {
                "Vous avez réussi à déverrouiller la prochaine série de questions"
              }
            </p>
            <p className={`mt-2 text-center `}>{"GET - SET - ALLER !"}</p>
            <div className="flex flex-col">
              <Button
                name={" Joue Maintenant "}
                handleClick={onHandlePlay}
                className={`button-theme pt-2 pl-4 pr-4 pb-2 bg-sky-800  hover:bg-sky-600 font-bold text-white rounded-3xl mt-4 
               `}
              />
            </div>
          </div>
        </div>
      )}
      <div>
        {counter && (
          <div className="Modal  animate-fade flex z-30 flex-col justify-center items-center ">
            <div className="modal_fade bg-white max-w-[480px] rounded-xl p-4 m-4 flex flex-col justify-center items-center">
              <p className="text-center">Nous traitons votre demande. S'il vous plaît, attendez.</p>
              <div className="mt-4" style={Parentdiv}>
                <div style={Childdiv}></div>
              </div>
            </div>
          </div>
        )}
        {pending && (
          <div className="Modal  animate-fade flex z-30 flex-col justify-center items-center ">
            <div className="modal_fade bg-white max-w-[480px] rounded-xl p-4 m-4 flex flex-col justify-center items-center">
              <h5 className="my-3 text-center text-xl font-small">
                Votre demande de recharge a échouée. Veuillez réessailler plus
                tard avec un solde suffisant!
              </h5>
              <Button
                name=" d'accord "
                handleClick={handleQuestion}
                className="bg-sky-800 m-auto py-2 px-16 text-white rounded-full font-medium text-lg"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
