import React, { useEffect, useState } from "react";
import "./Nav.scss";
import { Link, useLocation } from "react-router-dom";
import { API_SWIP_URLS } from "../../Shared/Constant";
import { POST } from "../../Shared/Axios";
import { Buffer } from "buffer";

const SideBar = () => {
    const [menu, setMenu] = useState("")
    const getMsisdn=window.localStorage.getItem("msisdn")
    const location = useLocation();
    useEffect(() => {
        const data = {
            userId: getMsisdn,
            operatorId: "mtn_congo",
        };
       
        POST(API_SWIP_URLS.Config, data)
            .then((res) => {
                setMenu(res?.menu);
            })
            .catch((err) => console.log(err));
    }, [])
    const DecodeBase64 = (str) => {
        let buffered = str && Buffer.from(str, "base64").toString("");
        return buffered;
      };
    return (
//         <nav className="navigation pl-6 pt-20 " >
//             <div>
//                 {menu?.length&&menu?.map((items,i) =>{return (
//                     <div className="mt-1 mb-1" key={i}>
//                         <Link to={items?.link}>{DecodeBase64(items?.title)}</Link>
//                     </div>
//                 )})}
               
//             </div>
//         </nav>

<nav className="navigation pt-[6rem]">
<div>
  {menu?.length &&
    menu?.map((items, i) => {
      return (
        <div
          className={`list  ${
            location.pathname === items.link || (i === 0 && location.pathname === '/')
              ? 'active-sidebar'
              : 'text-white'
          }`}
          key={i}
        >
          <Link
            to={items.link}
            className={`${location.pathname === items.link ? 'active-sidebar' : 'text-white'}`}
          >
            {DecodeBase64(items?.title)}
          </Link>
        </div>
      );
    })}
</div>
</nav>

    );

};
export default SideBar;
