import React, { useEffect, useState } from "react";
import { API_SWIP_URLS } from "../Shared/Constant";
import { POST } from "../Shared/Axios";
import "./swip_home.scss";
import { Loader, Button, DecodeBase64 } from "../Components";
import { useLocation, useNavigate } from "react-router-dom";
import { SwipeLogo } from "../Utilies/ExportImages";
import { SendGuiDataEvents } from "../commonScript";

export const Home = () => {
  const navigate = useNavigate();
  const [swipData, setSwipData] = useState("");
  const [questions, setQuestions] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [registerData, setRegisterData] = useState({});
  const location = useLocation();
  var UId = location.search ? location.search.split("=")[1] : null;
  const getUid = localStorage.getItem("uid")
  const getMsisdn = window.localStorage.getItem("msisdn");
  const heUrl = localStorage.getItem("heUrl");
  console.log("uid ni mili::",UId,heUrl);
  useEffect(() => {
    getHomeScreenData();
    if (UId !== null && UId !== undefined && UId != "") {
      window.localStorage.setItem("uid", UId);
      var msisdn = DecodeBase64(UId).split("=")[1];
      const updateMsisdn=msisdn.split("&")[0]
      console.log(msisdn,"mmsmmmmm",updateMsisdn);
      window.localStorage.setItem("msisdn", updateMsisdn);
    }
    if((UId == null || UId == undefined || UId == "")&&(!getMsisdn||getMsisdn==null)&&(heUrl)){
      console.log("uid ni mili222::",heUrl,heUrl);
      heUrl&& window.location.replace(heUrl);
    }
  }, [heUrl]);

  // useEffect(() => {
  //   console.log("registerData",registerData)
  //   if (
  //     registerData.status == "SUCCESS" &&
  //     registerData.currentStatus === "active" &&
  //     registerData.subscription.status === false
  //   ) {
  //     window.location.replace(swipData.he);
  //   }
  // }, [questions]);
  const getHomeScreenData = async () => {
    const data = {
      operatorId: "mtn_congo",
      userId: getMsisdn ? getMsisdn : "",
    };
    setIsLoading(true);
    POST(API_SWIP_URLS.Config, data)
      .then((res) => {
        console.log("api call", res);
        if (res.status !== "FAILURE") {
          setSwipData(res);
          setIsLoading(false);
          window.localStorage.setItem("heUrl",res.he);
        }
      })
      .catch((err) => console.log(err));
  };

 

  const onHandleBuyMore = async () => {
    let guiEvents={};
    guiEvents['page']="home";
    guiEvents["event"] = "playButton_clicked";
    await SendGuiDataEvents(guiEvents);

    setIsLoading(true)
    const regData = {
      userId: getMsisdn,
      operatorId: "mtn_congo",
      questions: true,
    };
    POST(API_SWIP_URLS.Register, regData)
      .then(async (res) => {
        setRegisterData(res);
        const currentStatus = res.currentStatus
        console.log("currentStatus",currentStatus)

       
        console.log("status",res.subscription.status)

        if(res.subscription.status == true){
          let guiEvents={};
          guiEvents['page']="subscription";
          guiEvents["event"] = "subscribed_user";
          guiEvents["currentStatus"] = currentStatus;
          await SendGuiDataEvents(guiEvents)
        }

        if(res.currentStatus === "active" || currentStatus === "demo"){
          Questions()
        }
        else{
          let guiEvents={};
          guiEvents['page']="subscription";
          guiEvents["event"] = "low_balance";
          guiEvents["currentStatus"] = currentStatus;
          await SendGuiDataEvents(guiEvents)
          //  redirectToHe(swipData.he)
          setTimeout(() => {
            window.location.replace(swipData.he);
        }, 2000);
        }
      })
      .catch((err) => console.log(err));
     
  };

  

  const Questions = () => {
    const data = {
      operatorId: "mtn_congo",
      questions: true,
      userId: getMsisdn,
    };
    POST(API_SWIP_URLS.Questions, data)
      .then((res) => {
        setQuestions(res);
        setIsLoading(false)
        if (res.status && res.status.toUpperCase() == "SUCCESS") {
          console.log("question api ::::", questions);
            navigate("/swipe-cards");
        }
        if (res.status && res?.status.toUpperCase() === "FAILURE") {
          console.log("failed question api")
          if (res.packs && res.packs[0].packType.toUpperCase() == "TOPUP") {
            console.log("pack type topup question api")
            navigate("/buy", { state: { questions: res } });
          }
         else if (res?.packs && res?.packs[0]?.packType.toUpperCase() == "SUBSCRIPTION") {
            window.location.replace(swipData.he);
          } else {
            console.log("info page")
            navigate("/info");
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(()=>
   {  
    if(getUid || UId)
    {
    let guiEvents={};
    guiEvents['page']="home";
    guiEvents["event"] = "open";
    SendGuiDataEvents(guiEvents);
    }
   },[])


  return (
    <div className="main home h-screen relative  flex flex-col  justify-between p-2 pb-32">
      {isLoading ? (
        <Loader />
      ) : ("")}
        <>
          <div className="swip-logo">
            <img src={SwipeLogo} alt="logo" width="150" />
          </div>

          <div className="flex flex-col justify-center text-justify items-center p-4 -mt-4 text-white">
            <h1 className="font-bold text-2xl text-center">
              {swipData && DecodeBase64(swipData?.launchscreen.title)}
            </h1>
            <p className="text-center font-medium text-align-last p-2">
              {swipData && DecodeBase64(swipData?.launchscreen.subtitle)}
            </p>
            <Button
              name={swipData && DecodeBase64(swipData?.launchscreen.buttontext)}
              className="bg-sky-800 w-full font-bold rounded-3xl px-4 py-2 text-lg button-theme"
              handleClick={onHandleBuyMore}
            />
          </div>
        </>
      
    </div>
  );
};
