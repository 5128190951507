import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Components/SideBar/term.scss";

function Terms() {
  const navigate = useNavigate();
  const [checkedState, setCheckedState] = useState(false);
  const [warningState, setwarningState] = useState(false);

  const clickCheckBox = () => {
    setCheckedState(!checkedState);
  };
useEffect(() => {
    window.addEventListener("popstate", () => {
        navigate(1);
      });
}, [])

  const clickHandler = () => {
    if (checkedState) {
      navigate("/home");
    } else {
      setwarningState(true);
    }
  };
  const getLang = window.localStorage.getItem("lang");
  return (
    <div className="">
      <div style={{ overflow: "scroll" }}>
        <section className="Modal terms-container-lang  ">
          <div className="modal_fade card-lang mt-8">
            <div className="card-body ">
              <h1
                className={`card-title h5 mb-2 ${
                  getLang == "ar" ? "arabic-lang" : ""
                }`}
              >
                {getLang === "en" && "TERMS AND CONDITIONS"}
                {getLang === "fr" && "TERMES ET CONDITIONS"}
              </h1>
              <div>
                <h6
                  className={`font-bold mb-1 `}
                >
                  {getLang === "en" &&
                    `For conducting the content service "Swipe & Win"`}
                  {getLang === "fr" &&
                    `Pour la gestion du service de contenu "Swipe & Win"`}
                </h6>
                <ol
                  className={`text-gray-500 ${
                    getLang == "ar" ? "arabic-lang" : ""
                  }`}
                >
                  <li>
                    {getLang == "en" && "Available SMS short-codes"}
                    {getLang == "fr" && "Codes abrégés SMS disponibles"}
                      <ol
                      className={`text-gray-500 `}
                    >
                      <li className="pl-4">
                        {getLang == "en" &&
                          ` The subscription to the “Swipe & Win” content service is available in Arabic and English languages; it has daily cost XXX with 1 day free of charge trial period.`}
                        {getLang == "fr" &&
                          `L'abonnement au service de contenu "Swipe & Win" est disponible en langues arabe et anglaise ; il a un coût quotidien de XXX avec une période d'essai gratuite d'un jour.`}
                      </li>
                      <li className="pl-4">
                        {getLang == "en" &&
                          `All of the SMS sent to the above short-code are free of charge`}
                        {getLang == "fr" &&
                          "Tous les SMS envoyés au numéro court ci-dessus sont gratuits"}
                       </li>
                      <li className="pl-4">
                        {getLang == "en" &&
                          `Operator subscribers may opt in to the subscription service “Swipe & Win” by:`}
                        {getLang == "fr" &&
                          `Les abonnés Opérateurs peuvent souscrire au service d'abonnement "Swipe & Win" en :`}
                       </li>
                      <ol
                        className={`text-gray-500 `}
                      >
                        <li className="pl-4">
                          {getLang == "en" &&
                            `Sending a valid keyword via SMS to the short-code XXX (please check section 8.2 for keywords table)`}
                          {getLang == "fr" &&
                            "Envoi d'un mot-clé valide par SMS au code abrégé XXX (veuillez consulter la section 8.2 pour le tableau des mots-clés)"}
                          </li>
                        <li className="pl-4">
                          {getLang == "en" && " Dialing the USSD command XXX"}
                          {getLang == "fr" &&
                            "Composition de la commande USSD XXX"}
                        </li>
                        <li className="pl-4">
                          {getLang == "en" &&
                            `  By following the “Swipe & Win” link via “XXX” app`}
                          {getLang == "fr" &&
                            `En suivant le lien "Swipe & Win" via l'application "XXX"`}
                         </li>
                        <li className="pl-4">
                          {getLang == "en" &&
                            " following the link XXX and get registered online"}
                          {getLang == "fr" &&
                            "en suivant le lien XXX et inscrivez-vous en ligne"}
                         </li>
                      </ol>
                      <li className="pl-4">
                        {getLang == "en" &&
                          ` Upon registration, the users will receive the welcome SMS, 10 points for the upcoming weekly prize and the “Swipe & Win”`}
                        {getLang == "fr" &&
                          `Lors de l'inscription, les utilisateurs recevront le SMS de bienvenue, 10 points pour le prochain prix hebdomadaire et le "Swipe & Win"`}
                        </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
            <label
              id="term-checkbox"
              className={`flex  mt-2   `}
            >
              <input
                type="checkbox"
                checked={checkedState}
                className={` w-6 h-6 mr-2 `}
                onChange={clickCheckBox}
              />
              {getLang == "en" && "Kindly select the checkBox to proceed "}
              {getLang == "fr" && "veuillez cocher la case pour continuer "}
            </label>
            <br />
            <a
              type="Submit"
              id="submit-btn"
              className={`flex rounded-full text-white p-2 justify-center align-center text-black bg-sky-600 ${
                checkedState
                  ? "bg-sky-800 cursor-pointer text-white"
                  : ""
              }`}
              onClick={clickHandler}
            >
              {getLang == "en" && "Submit"}
              {getLang == "fr" && "nous faire parvenir"}
             </a>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Terms;
