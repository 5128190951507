import React, { useState, useEffect } from "react";
import "./Assets/Css/style.scss";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import {Home,BuyMore,Info,Swipe4win,SelectPage} from "./view"
import {HowToPlay,Faq,LeaderBoard,TermNCondition, Subscribe} from "./Components/SideBar"
import { ProtectedRoute } from "./view/ProtectedRoute";
import useOnline from "./Utilies/useOnline";

function App() { 
 const isOnline=useOnline()

  return (
    <div className="App">
      {isOnline ? (
        <Router>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/home" exact element={<Home />} />
            <Route path="/info" element={<ProtectedRoute Component={Info} />} />
            <Route path="/buy" element={<ProtectedRoute Component={BuyMore} />} />
            <Route path="/swipe-cards" element={<ProtectedRoute Component={Swipe4win} />} />
            <Route path="/leaderboard" element={<ProtectedRoute Component={LeaderBoard} />} />
            <Route path="/faqs" element={<Faq />} />
            <Route path="/how-to-play" element={<HowToPlay />} />
            <Route path="/terms-conditions" element={<TermNCondition />} />
            <Route path="/unsub" element={<Subscribe />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      ) : (
        <div className="text-xl font-bold text-white flex  h-screen items-center justify-center text-center">
          your internet is not working
        </div>
      )}
    </div>
  );
}

export default App;
