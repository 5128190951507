import React, { useEffect, useState } from "react";
import { BgImage,RedBanner } from "../Utilies/ExportImages";
import { API_SWIP_URLS } from "../Shared/Constant";
import { POST } from "../Shared/Axios";
import { Button,DecodeBase64,Header } from "../Components";
import "./swip4win.scss"
function Info() {
  const [questions, setQuestions] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [playlater, setPlaylater] = useState(false);
  const getMsisdn=window.localStorage.getItem("msisdn")
  useEffect(() => {
    Questions();
  }, []);

  const style = {
    backgroundImage: `url(${BgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: " center center",
    width: " 100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "auto",
    textAlign: "center",
    padding: "10px",
    marginTop: "1rem",
    borderRadius: "10px",
    objectFit: "cover",
  };
  const Questions = () => {
    const data = {
      operatorId: "mtn_congo",
      questions: true, 
      userId: getMsisdn, 
    };
    POST(API_SWIP_URLS.Questions, data)
      .then((res) => { 
        setQuestions(res); 
        setIsLoading(false); 
      })
      .catch((err) => console.log(err));
  };
  const infoStyle = {
    backgroundImage: `url(${RedBanner})`,
    height: "50px",
    textAlign: " center",
    width: "100%",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    fontSize: ".9rem",
    color: " #fff",
  };
  const HandlePlayMore = () => {
    setPlaylater(true);
    Questions()
  };
  return (
    <div className="main">
      <Header />
      <div style={style}>
        <div className="info-title">
          {questions.info && DecodeBase64(questions.info.title)}
        </div>
        <div className="info-subtitle">
          {questions.info && DecodeBase64(questions.info.subtitle)}
        </div>
        <div className="info-info" style={infoStyle}>
          {questions.info && DecodeBase64(questions.info.info)}
        </div>
        <div className="info-text">
          {questions.info && DecodeBase64(questions.info.text)}
        </div>
        <hr className="info-hr" />
        <Button
          className="bg-sky-800 text-white font-bold rounded-3xl p-3 mt-3 capitalize button-theme"
          name="Play More"
          handleClick={HandlePlayMore}
        />
        {playlater && (
          <div className="playlater-text">
            {questions.playLater && DecodeBase64(questions.playLater.next)}
          </div>
        )}
      </div>
    </div>
  );
}

export default Info;
