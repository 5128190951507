import React from "react";
import { Header } from "..";

const TermNCondition = () => {
  return (
    <div className="main">
      <Header />
      <section className="info-container">
        <div className="card_menu ">
          <div className="card-body">
            <h1 className={`text-center text-xl font-bold my-2`}>
              {"REGLEMENT DU SERVICE "}
              <b>"MTN Gains"</b>
            </h1>
            <div className="p-3">
              <h6 className={`font-semibold mb-2 text-gray-500`}>
                {` MTN CONGO SA a lancé un service de quiz gratifiant dénommée "MTN Gains". "`}
              </h6>
              <h6 className="font-semibold">{"Article 1 : OBJET"}</h6>
              <p className="text-gray-500 mb-2">
                {
                  " MTN Gains est un service QUIZ interactif basé sur le WEB qui vous permet de totaliser des points en répondant à des questions et prétendre gagner des lots. "
                }
              </p>
              <p className="text-gray-500 mb-4">
                {"Les lots mis en jeu sont décrits en l’article 4 ci-dessous."}
              </p>
              <h6 className="font-semibold mb-2">
                {
                  " Article 2 : CONDITIONS DE PARTICIPATION ET MECANISME DU JEU "
                }
              </h6>
              <p className="text-gray-500 mb-2">
                {" "}
                {
                  "La participation au quizz de MTN Gains, est ouverte à tous les abonnés de MTN Congo. Pour y participer, il est important de remplir les conditions décrites ci-après :  "
                }
              </p>
              <ul className="list-disc list-outside pl-3 text-gray-500">
                <li>
                  {" "}
                  {
                    " L’abonné doit accéder au site web de MTN à partir de son téléphone en tapant : "
                  }
                  <a className="text-[#007bff]" href="https://www.mtngains.com">
                    www.mtngains.com
                  </a>
                </li>
                <li>
                  {` Souscrire au service à raison de 50FCFA par jour avec renouvellement automatique.
Vous avez un accès gratuit de 2 jours si vous utilisez le service pour la première fois. `}
                </li>
                <li>
                  {
                    " Une fois que l'abonné a souscrit, la plateforme lui proposera 5 questions auxquelles il devra répondre. "
                  }
                </li>
                <li>
                  {
                    " Pour répondre, il suffit de balayer les réponses affichées à l'écran du smartphone vers la droite ou la gauche. "
                  }
                </li>
                <li>
                  {
                    " L’abonné aura la possibilité de débloquer d’autres questions moyennant 100 FCFA pour 5 questions. "
                  }
                </li>
                <li>
                  {
                    " L'abonné disposera de 10 secondes pour répondre à une question. Passées les 10 secondes sans réponse, la réponse sera considérée comme fausse et l’abonné n'aura pas de points. "
                  }
                </li>
              </ul>
              <p className="text-gray-500 my-2">
                <strong>NB</strong>
                {
                  ": Dans le cas où plusieurs joueurs se retrouvent à égalité des points MTN CONGO précèdera à un tirage au sort pour désigner un gagnant parmi tous ces concernés. "
                }
              </p>
              <h6 className="font-semibold">
                {" Article 3 : DETERMINATION DES GAGNANTS "}
              </h6>
              <ul className="list-disc list-outside pl-3 text-gray-500">
                <li>
                  {
                    "  Les gagnants seront déterminés sur la base du nombre de points, cumulés chaque semaine en jouant au quiz. "
                  }
                </li>
                <li>
                  {
                    "  Le participant- ayant cumulé le plus grand nombre de points dans la semaine, sera désigné gagnant de la semaine. "
                  }
                </li>
                <li>
                  {
                    "  En cas d’égalité des points entre deux ou plusieurs participants, un tirage au sort sera effectué pour déterminer le gagnant. "
                  }
                </li>
                <li>
                  {
                    "  Au début de chaque semaine, les points cumulés la semaine précédente seront réinitialisés  "
                  }
                </li>
                <li>
                  {
                    "  Tout gagnant de lot hebdomadaire, ne sera pas éligible pour gagner à nouveau au cours des deux prochains mois.  "
                  }
                </li>
                <li>
                  {
                    "  Tout gagnant de lot hebdomadaire ne sera pas autorisé à s’abonner au service MTN Gains, à répondre aux questions ou à débloquer des questions pour les deux prochains mois. En essayant de s’abonner ou de débloquer des questions.  "
                  }
                </li>
                <li>
                  {
                    "   Au cas où le gagnant de lot hebdomadaire s’abonne au service au service MTN Gains, répond aux questions ou débloque des questions au cours des deux prochains mois. Il ne sera pris en compte dans le processus de détermination des gagnants.  "
                  }
                </li>
              </ul>
              <h6 className="text-semibold">
                {" Article 4: DESCRIPTION DES LOTS A GAGNER "}
              </h6>
              <p className="text-gray-500">
                {
                  "  Chaque semaine le participant ayant cumulé le plus grand nombre de point gagnera 50.000FCFA "
                }
              </p>
              <p className="text-gray-500">1er gagnant : 25.000FCFA</p>
              <p className="text-gray-500">2ème gagnant : 15.000FCFA</p>
              <p className="text-gray-500">3ème gagnant : 10.000FCFA</p>
              <p className="text-gray-500 mb-4">
                {
                  "Le gagnant recevra son lot par transfert via MTN Mobile Money."
                }
              </p>
              <h6 className="text-semibold">
                {"  Article 5 : REMISE DES LOTS "}
              </h6>
              <ul className="list-disc list-outside pl-3 text-gray-500">
                <li>
                  {" "}
                  {
                    "  MTN Congo informera les gagnants de chaque lot par voie d'appel téléphonique, SMS et par les réseaux sociaux (Facebook, Instagram, twitter). "
                  }
                </li>
                <li>
                  {
                    " Les noms, et les numéros de téléphones des gagnants pourront faire l’objet d’une publication dans les médias et sur les réseaux sociaux.  "
                  }
                </li>
                <li>
                  {
                    "   La remise des lots se fera par Mobile Money à une date déterminée par MTN Congo. "
                  }
                </li>
                <li>
                  {
                    "  Les contrôles d’identité des abonnés et de comptes Mobile Money se feront au préalable pour s’assurer avant la remise des lots. "
                  }
                </li>
                <li>
                  {
                    "  S’il y a problème d’identité ou compte Mobile Money, l’abonné devra se présenter 48 heures avant la date de remise pour régler selon la procédure de modification en vigueur chez MTN. "
                  }
                </li>
                <li>
                  {
                    "  Si aucun gagnant ne se présente pour ce changement et dépassant les délais, les lots non bénéficiés resteront la propriété de MTN Congo. "
                  }
                </li>
                <li>
                  {
                    "  L’abonné ayant gagné s’il désiste ou s’il n’est pas joignable pendant la période que MTN CONGO cherche à entrer en contact avec lui ce dernier ne bénéficiera pas du lot et sera directement remplacé par un substitut "
                  }
                </li>
              </ul>
              <h6 className="text-semibold">
                {"   Article 6 : RENONCIATION DES PARTICIPANTS "}
              </h6>
              <p className="text-gray-500 mb-2">
                {
                  " Les participants renoncent de manière irrévocable à toute action en dédommagement contre MTN Congo et/ou pour tout éventuel retard dans le cadre de l’organisation du jeu, ainsi que pour tout dysfonctionnement ou erreur dû à une panne du matériel et/ou du système informatique, ou due à toute autre circonstance indépendante de la volonté de MTN Congo. MTN Congo prendra toutes les dispositions nécessaires pour remédier le cas échéant à ce type d’évènement. "
                }
              </p>
              <h6 className="text-semibold">
                {"Article 7 : ENGAGEMENT DES GAGNANTS"}
              </h6>
              <p className="text-gray-500 mb-2">
                {
                  " Du fait de sa simple participation au jeu, le Gagnant accepte sans réserve que MTN Congo fasse usage à travers les médias de son nom, de sa voix ou de son numéro d’appel obtenus dans le cadre du jeu à des fins publicitaires ou de relations publiques, sans que cela n’implique une rémunération autre que le gain décrit à l’article 4. "
                }
              </p>
              <h6 className="text-semibold">
                {"   Article 8 : LIMITATION DE RESPONSABILITE   "}
              </h6>
              <p className="text-gray-500 mb-2">
                {
                  " La responsabilité de MTN Congo ne saurait être engagée si, du fait d’un cas de force majeure ou d’une circonstance indépendante de sa volonté, le Jeu objet des présentes devait être modifié, écourté ou annulé. Aucune indemnité ne pourra être réclamée de ce chef. "
                }
              </p>
              <p className="text-gray-500 mb-2">
                {
                  "  MTN Congo rappelle aux participants les caractéristiques et les limites du réseau de télécommunications et décline toute responsabilité quant aux conséquences liées à l’interruption ou au dysfonctionnement du service MTN Gains. "
                }
              </p>

              <h6 className="text-semibold">
                {"  Article 9 : FORCE MAJEURE  "}
              </h6>
              <p className="text-gray-500 mb-2">
                {
                  "  La responsabilité de MTN Congo ne saurait être engagée si pour un cas de force majeure ou du fait d’une circonstance indépendante de sa volonté, le Jeu devait être modifié, écourté ou annulé. MTN Congo se réserve dans ce cas, la possibilité de prolonger la période de participation et de reporter toute date annoncée.  "
                }
              </p>
              <h6 className="text-semibold">
                {" Article 10 : CONVENTION DE PREUVE "}
              </h6>
              <p className="text-gray-500 mb-2">
                {
                  "  MTN Congo a mis en place les moyens techniques nécessaires pouvant démontrer la participation ou la non-participation d’une personne au Jeu. Par conséquent, sauf erreur manifeste, les données contenues dans ses systèmes d'information ont force probante quant aux informations résultant d'un traitement informatique relatif au Jeu. "
                }
              </p>
              <p className="text-gray-500 mb-2">
                {
                  "  MTN Congo pourra se prévaloir aux fins de preuve, des programmes, données, fichiers, enregistrements, opérations et autres éléments, sous formats ou supports électroniques, établis, reçus ou conservés dans ses systèmes d'information. "
                }
              </p>
              <p className="text-gray-500 mb-2">
                {
                  " Les Participants s’engagent à ne pas contester la recevabilité, la validité ou la force probante de ces éléments s’ils sont produits comme preuves par MTN Congo dans le cadre d’une procédure contentieuse ou dans tout autre cadre. "
                }
              </p>
              <h6 className="text-semibold">
                {" Article 11 : CLAUSE SURROGATOIRE "}
              </h6>
              <p className="text-gray-500 mb-2">
                {
                  "Tout autre texte figurant sur les autres supports commerciaux de MTN Congo (Affiches, affichettes, spots radio, spot TV, gazettes, Push SMS, EOCN, CRBT et bien d’autres) et relatif au jeu objet des présentes sera considéré comme annexe du présent règlement.  "
                }
              </p>
              <h6 className="text-semibold">
                {" Article 12 : REGLEMENT DE DIFFEREND "}
              </h6>
              <p className="text-gray-500 mb-2">
                {
                  "  La participation à ce jeu vaut acceptation pure et simple du présent règlement par le participant. Tout litige ou contestation relatif à l'interprétation ou à l'application du présent règlement fera l'objet d'un arrangement à l’amiable.  "
                }
              </p>
              <p className="text-gray-500 mb-2">
                {
                  " A défaut d'un tel arrangement, il est reconnu compétence exclusive aux tribunaux d'instance de Brazzaville.  "
                }
              </p>
              <p className="text-gray-500 mb-2">
                {
                  " En cas de fraude manifeste en rapport avec le jeu, MTN Congo se réserve le droit d'engager des poursuites judiciaires à l'encontre des contrevenants. "
                }
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default TermNCondition;
